import { mapState } from "vuex";
import { Dialog, Toast } from "vant";
import RetailOrderItem from "./components/retail-order-item/index.vue";
import { getToken } from "@/utils/localStorage.js";
import { wxPay } from '@/utils/wx-promise';
import { getMarketingOrderList, cancelMarketingOrder, payMarketingOrder, refundMarketingOrder, finishMarketingOrder } from "@/service/enroll-activity/index.js";

export default {
    name: "marketing-order-list",

    components: {
        RetailOrderItem
    },

    computed: {
        ...mapState({
            userId: (state) => state.user.user.id,
        }),
    },

    data() {
        return {
            active: 0,
            tabs: [
                { name: "全部", state: '-1' },
                { name: "待支付", state: '1' },
                { name: "待发货", state: '2' },
                { name: "待收货", state: '3' },
                { name: "已取消", state: '5' },
            ],
            orderList: [],
        };
    },

    methods: {
        //  根据state值设置当前活跃的tab
        getActiveIndexByState(state) {
            let index = this.tabs.findIndex(
                (tab) => String(tab.state) === String(state)
            );
            if (index === -1) {
                index = 0;
            }
            this.active = index;
        },

        //  点击tab
        onClickTab() {
            //  更新一下路由
            this.$router.push({
                name: this.$route.name,
                query: { state: this.tabs[this.active].state },
            });
            this.fetchData();
        },

        //  请求订单列表
        fetchData() {
            this.orderList = [];
            const state = this.tabs[this.active].state;
            getMarketingOrderList({ userId: this.userId, state }).then((res) => {
                this.orderList = res || [];
            });
        },

        //  点击订单
        onClickOrder(order) {
            this.$router.push({
                name: "enroll-retail-order-detail",
                query: { 
                    orderId: order.code,
                    from: 'marketing'
                },
            });
        },

        //  支付
        onPayOrder(order) {
            Dialog.confirm({
                title: "支付订单",
                message: "确定支付订单吗～",
            })
                .then(() => {
                    payMarketingOrder({ code: order.code, token: getToken() }).then((res) => {
                        // 调用内置微信支付方法  发起支付
                        const payParams = {
                            appId: res.appId,
                            timeStamp: res.timeStamp,
                            nonceStr: res.nonceStr,
                            package: res.packageValue,
                            signType: res.signType,
                            paySign: res.paySign
                        }
                        wxPay(payParams).then(wxRes => {
                            this.$router.push({
                                name: 'enroll-retail-success-info'
                            })
                        })
                    });
                })
                .catch((err) => {
                    console.error(err);
                });
        },

        //  取消订单
        onCancelOrder(order) {
            Dialog.confirm({
                title: "取消订单",
                message: "确定取消该订单吗～",
            })
                .then(() => {
                    cancelMarketingOrder({ code: order.code }).then((res) => {
                        Toast("取消订单成功～");
                        this.fetchData();
                    });
                })
                .catch((err) => {
                    console.error(err);
                });
        },

        // 退款
        onRefund(order) {
            Dialog.confirm({
                title: "申请退款",
                message: "确定申请退款吗～",
            })
            .then(() => {
                refundMarketingOrder({ code: order.code }).then((res) => {
                    Toast("申请退款成功～");
                    this.fetchData();
                });
            })
            .catch((err) => {
                console.error(err);
            });
        },

        // 确认收货
        onAffirm(order) {
            Dialog.confirm({
                title: "确认收货",
                message: "确定确认收货吗～",
            })
            .then(() => {
                finishMarketingOrder({ code: order.code }).then((res) => {
                    Toast("确认收货成功～");
                    this.fetchData();
                });
            })
            .catch((err) => {
                console.error(err);
            });
        },
    },

    created() {
        const { state } = this.$route.query;
        this.getActiveIndexByState(state);
        this.fetchData();
    },
};
