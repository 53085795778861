const map = {
    "0": "初始",
    "1": "待支付",
    "2": "待发货",
    "3": "待收货",
    "4": "已完成",
    "5": "已取消",
    "6": "待退款",
    "7": "已退款",
};

//  获取订单状态
export function getMarketingState(state) {
    return map[state];
}
