import RetailProductItem from "../retail-product-item/index.vue";
import Rmb from '@/components/common/rmb/index.vue'
import { getMarketingState } from "@/constants/marketing.js";

export default {
    name: "retail-product-list",
    components: { 
        RetailProductItem,
        Rmb
    },

    filters: {
        getMarketingState(state) {
            return getMarketingState(state);
        },
    },

    props: {
        order: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    
    computed: {
        //  已选商品件数
        sumCount() {
            return (this.order.productList || []).reduce((sum, p) => {
                return sum + p.quantity;
            }, 0);
        },
    },
    
    methods: {
        //  取消
        cancel() {
            this.$emit("cancel", this.order);
        },
        //  支付
        pay() {
            this.$emit("pay", this.order);
        },
        // 退款
        refund() {
            this.$emit("refund", this.order);
        },
        // 确认
        affirm() {
            this.$emit("affirm", this.order);
        },
        click() {
            this.$emit("click", this.order);
        },
    },
};
